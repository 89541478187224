$(document).ready(function(){

	//js_bank-article
	//js_bank-hours
	//js_bank-price
	//js_bank-intervention

	$('.js_bank-article').change(function(){
        searchGammes()
		searchPriceBank()
	})
	$('.js_bank-hours').change(function(){
		searchPriceBank()
	})

	$('.js_gestAdmin-article').change(function(){
        searchGammesGestAdmin()
		searchPriceGestAdmin()
	})
	$('.js_gestAdmin-hours').change(function(){
		searchPriceGestAdmin()
	})


});

function searchGammes(){
    if($('.js_bank-article').val() != null && $('.js_bank-article').val() != ''){
        $.ajax({
            url: route('work.getGammes', {
                work: $('.js_bank-article').val(),
                intervention: $('.js_bank-intervention').val(),
            }),
            dataType: 'json',
            success: function(data){
                $('.js_hours-container').html(data.view)
            }
        });
    }
    else{
        $('.js_hours-container').html('');
    }
}

function searchGammesGestAdmin(){
    if($('.js_gestAdmin-article').val() != '' && $('.js_gestAdmin-article').val() != null){
        $.ajax({
            url: route('work.getGammes', {
                work: $('.js_gestAdmin-article').val(),
                intervention: $('.js_gestAdmin-intervention').val(),
            }),
            dataType: 'json',
            success: function(data){
                $('.js_gestAdmin_hours-container').html(data.view)
            }
        });
    }
    else{
        $('.js_gestAdmin_hours-container').html('');
    }
}

function searchPriceBank(){
	$.ajax({
		url: route('dailyreport.getPrice', {
			intervention: $('.js_bank-intervention').val()
		}),
		data: {
			work_id: $('.js_bank-article').val(),
			hours: $('.js_bank-hours').val(),
		},
		dataType: 'json',
		success: function(data){
			$('.js_bank-price').val(data.result)
		}
	});
}

function searchPriceGestAdmin(){
	$.ajax({
		url: route('dailyreport.getPrice', {
			intervention: $('.js_gestAdmin-intervention').val()
		}),
		data: {
			work_id: $('.js_gestAdmin-article').val(),
			hours: $('.js_gestAdmin-hours').val(),
		},
		dataType: 'json',
		success: function(data){
			$('.js_gestAdmin-price').val(data.result)
		}
	});
}
