$(document).ready(function(){
	$('#js_notifysecretary').submit(function(){
		$.ajax({
			url : route('interventions.notifySecretary'),
			type : 'POST',
			data : $('#js_notifysecretary').serialize(),
			success : function(data){
				Swal.fire({
					title: data.title,
					text: data.message,
					icon: data.type,
					confirmButtonText: 'Fermer'
				})
			}
		});

		$('#contactIntrouvable').modal('hide')

		return false
	});
});