$(document).ready(function(){

	new AutoNumeric.multiple('.js_currency', {
		suffixText: " €",
		decimalCharacter: ",",
		decimalCharacterAlternative: ".",
		digitGroupSeparator: ".",
		unformatOnSubmit: true
    }).french;

});
