
window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
    window.jqueryui = require('jquery-ui');
    autocomplete = require('jquery-ui/ui/widgets/autocomplete');
    window.moment = require('moment');
    window.Dropzone = require('dropzone');
    window.AutoNumeric = require('autonumeric');
    window.Swal = require('sweetalert2');

    require('bootstrap');
    require('@coreui/coreui');
    require('datatables.net-bs4');
    require('datatables.net-buttons-bs4');
    require('datatables.net-fixedcolumns-bs4');
    require('bs-stepper');
    require('typeahead');
    require('select2');
    require('jquery-datetimepicker');
    require('jquery-ui/ui/widgets/autocomplete.js');
    require('clockpicker/dist/jquery-clockpicker');

} catch (e) {}


//Autodisco OFF !!!
window.Dropzone.autoDiscover = false;
