$(document).ready(function(){
	if($('.roles').length > 0){
		$('#addNewRole').click(function(e){
			e.preventDefault()
			
			$('#roleForm').append(`
				<div class="form-group col-md-4 roles">
					<div class="input-group">
						<div class="input-group-prepend">
							<span class="input-group-text role-svg">
									<i class="fas fa-user"></i>
							</span>
						</div>
						<input class="form-control" name="newRole[]" value="" autocomplete="false" placeholder="Nom de votre rôle" type="text" />
						<span class="input-group-append">
							<a href="#" class="btn btn-danger btn-xs input-group-btn newRole"><i class="fas fa-trash"></i></a>
						</span>
					</div>
				</div>
			`)
		
			$('.newRole').click(function(e){
				e.preventDefault()
				$(this).parent().parent().parent().remove()
			});
		});
	}
});