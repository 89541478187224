$(document).on("change", ".js_current-year", function () {

    const year = $(this).val();
    let weeks;

    //HOTFIX : weeks in year

    weeks = moment(year, 'YYYY').isoWeeksInYear()

    $('.js_current-week').html('');
    for (i = 1; i <= weeks; i++) {

        var startDayOfWeek = moment().year(year).isoWeek(i).startOf('isoWeek').format("DD/MM/YYYY")
        var endDayOfWeek = moment().year(year).isoWeek(i).endOf('isoWeek').format("DD/MM/YYYY")

        $('.js_current-week').append($('<option>', {
            value: i,
            text: 'Semaine ' + i + ' | ' + startDayOfWeek + ' / ' + endDayOfWeek
        }))
    }

});



