$(document).ready(function(){
	$('.js_btn-add-resource').on('click', addResource)
	$('.js_resource_container').on('click', '.removeField', function(){
		$('*[data-number="'+$(this).data('row-id')+'"]').remove()
    });
    $('.js_btn-add-resource-toDatabase').on('click',addResourceToDatabase);

	var results;
	$('.js_matricule-choose').select2({
		ajax: {
			url: route('resources.getAjax', {intervention: $('#matricule-choose').data('intervention')}),
			dataType: 'json',
			processResults: function (data) {
				var finalSelect = [{
						text: 'Intervenants du site',
						children: []
					},{
						text: 'Autres intervenants',
						children: []
					}
				];
				data.resources.forEach(resource => {
					resource.id = resource.registration_number_full;
					//
					var siteName = resource.site ?  ' - ' + resource.site.name : '';
					resource.text = resource.registration_number + " - " + resource.firstname + ' ' + resource.lastname + siteName;
					resource.collaborator_id == data.intervention.collaborator_id ? finalSelect[0].children.push(resource):finalSelect[1].children.push(resource)
				})
				return {
					results: finalSelect,
				}
			}
		},
	})
});

let addResource = () => {
	$.get(route('resources.get', {resource: $('.js_matricule-choose').val()}), function( data ) {
		if(data){
			//check if exist
			if($('*[data-number="'+data.registration_number+'"]').length == 0){
				var html = ''
				html += '<div class="row js_resource_row" data-number="'+data.registration_number+'">'
				html += $('.js_row-copy').html()
				html += '</div>'

				$('.js_resource_container').append(html);

				$('*[data-number="'+data.registration_number+'"]').find('.js_resource-id').val(data.id)
				$('*[data-number="'+data.registration_number+'"]').find('.removeField').data('row-id',data.registration_number)
				$('*[data-number="'+data.registration_number+'"]').find('.js_resource-registration_number').val(data.registration_number)
				$('*[data-number="'+data.registration_number+'"]').find('.js_resource-lastname').val(data.lastname)
				$('*[data-number="'+data.registration_number+'"]').find('.js_resource-firstname').val(data.firstname)
				$('*[data-number="'+data.registration_number+'"]').find('.js_resource-resource_type_id').val(data.resource_type.name)

			}
		}
	});
}

let addResourceToDatabase = () => {
    $('#js_intervenant-error-message').addClass('d-none');
    $.post(route('resources.add', {resource: $('.js_matricule-choose').val(), intervention: $('.js_matricule-choose').data('intervention')}), function( data ) {
        if(data.isDone){
            let clonedItem = $('.js_intervenants-list-item').last().clone();
            clonedItem.attr('action', route('dailyreport.destroy', data.report.id))
            clonedItem.find('.js_intervenant-name').text(data.resource.firstname+' '+data.resource.lastname)
            clonedItem.find('.js_intervenant-editlink').attr('href', route('dailyreport.edit',data.report.id))
            $('.js_intervenants-list').append(clonedItem);
        }
        else{
            $('#js_intervenant-error-message').html(data.message);
            $('#js_intervenant-error-message').removeClass('d-none');
        }
	}, 'json');
}

