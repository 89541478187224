$(function () {
    $('input[name="email"]').on('input', function (e) {
        updateUsernameInput(e.target);
    });
});

function updateUsernameInput(emailInput) {
    if (emailInput)
        $('input[name="username"]').val(emailInput.value.split('@')[0]);
}
