$(document).ready(function(){
	//$('.js_customer-code').on('keyup', syncCustomer)
	$('#contact_id').on('change', getContact)

	$('.js_customer-code').on('change', syncCustomer)

	$('.js_collaborator').on('change', syncDeliveryAddresses)

	$('.js_customer-code_search').on('change', function(){
		$('.js_customer-code_search-result').val($(this).val())
	})

	$('.js_customer-code').select2({
		ajax: {
			url: route('customerInfo.getAjax'),
			dataType: 'json',
			processResults: function (data) {
				data.customers.forEach(customer => {
					customer.text = customer.society + " - " + customer.code;
					customer.id = customer.code;
				})
				return {
					results: data.customers,
				}
			}
		},
	})
});

let syncCustomer = () => {
	$.get(route('customerInfo.get', {code: $('.js_customer-code').val()}), function( data ) {
		if(data){
            console.log(data);
			$('#contact_id').prop('disabled', false)
			$('#customer_information_id').val(data['id'])
			$('#society').val(data['society'])
			$('#type').val(data['type'])
			$('#address').val(data['address'])
			$('#address_more').val(data['address_more'])
			$('#postal_code').val(data['postal_code'])
			$('#city').val(data['city'])
			$('#country').val(data['country'])
			$('#billing_email').val(data['billing_email'])
			$('#phone').val(data['phone'])
			$('#vat_number').val(data['vat_number'])
			$('#type').val(data['type'])

			$('#contact_id').prop('disabled', false);
			$("#contact_id").html('');

			if(data.contacts.length > 0){
				$('.js_contact-client-exist').removeClass('d-none');
			}else{
				$('.js_contact-client-exist').addClass('d-none');
                clearContact()
			}
			$("#contact_id").append('<option value="">Nouveau contact client</option>')

			data.contacts.forEach(function(element, index) {
				if(index == 0 && element.code != null){
					syncContact(element)
				}
                if(element.code != null){
                    $("#contact_id").append('<option value="'+ element.id +'">'+ element.firstname + ' ' + element.lastname + '</option>')
                }
            });

            //Si le customer n'est lié à aucun user, il ne peut rien créer
            if(!data.hasUsers){
                $('button[name="submit_button"]').addClass('d-none');
                $('.js_hasUsers-alert').removeClass('d-none');
            }
            else{
                $('button[name="submit_button"]').removeClass('d-none');
                $('.js_hasUsers-alert').addClass('d-none');
            }

            // $("#delivery_address").html('<option value="">Nouvelle adresse</option>');

			// data.delivery_addresses.forEach(function(element, index) {
			// 	if(index == 0){
			// 		syncContact(element)
			// 	}
			// 	$("#delivery_address").append('<option value="'+ element.id +'">'+ element.name + '</option>')
			// });

			//$('#delivery_address_address').val(data.address);
			//$('#delivery_address_address_bis').val(data.address_bis);
			//$('#delivery_address_postal_code').val(data.postal_code);
			//$('#delivery_address_city').val(data.city);
			//$('#delivery_address_country').val(data.country);


			$('.js_client-type-client').removeClass('active')
			$('.js_client-type-client').removeClass('notActive')
			$('.js_client-type-fournisseur').removeClass('active')
			$('.js_client-type-fournisseur').removeClass('notActive')

			if(data['type'] == 'Client'){
				$('.js_client-type-client').addClass('active')
				$('.js_client-type-fournisseur').addClass('notActive')
			}else{
				$('.js_client-type-client').addClass('notActive')
				$('.js_client-type-fournisseur').addClass('active')

			}

		}else{
			$("#contact_id").html('')
			$('#contact_id').prop('disabled', true)
		}
	});
}

let syncDeliveryAddresses = () => {
	$.get(route('collaborators.get', {collaborator: $('.js_collaborator').val()}), function( data ) {
		if(data){
			$("#delivery_address").html('<option value="">Nouvelle adresse</option>');

			data.delivery_addresses.forEach(function(element, index) {
				$("#delivery_address").append('<option value="'+ element.id +'">'+ element.name + '</option>')
			});
		}else{
			$("#delivery_address").html('<option value="">Nouvelle adresse</option>');
		}
	});
}

let getContact = (element) => {
	if($('#contact_id').val()){
		$.get(route('contact.get', {contact: $('#contact_id').val()}), function( data ) {
			syncContact(data)
		})
	}else{
		clearContact()
	}
}

let syncContact = (element) => {
	$('#lastname').val(element.lastname)
	$('#firstname').val(element.firstname)
	$('#service').val(element.service)
	$('.phone-contact').val(element.phone)
	$('#mobile').val(element.mobile)
	$('#email').val(element.email)
	$('#comment1').val(element.comment1)
	$('#comment2').val(element.comment2)
}

let clearContact = () => {
    $('#lastname').val('');
    $('#firstname').val('');
    $('#service').val('');
    $('.phone-contact').val('');
    $('#mobile').val('');
    $('#email').val('');
    $('#comment1').val('');
    $('#comment2').val('');
}
