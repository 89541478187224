$(document).ready(function(){
	$('#js_submit-step2-notify').on('click', function(){

		if(!isNaN($('.js_contact-id').val())){
			return true
		}else{
			Swal.fire({
				title: 'Impossible de poursuivre',
				text: 'Vous n\avez pas ajouté de contact pour cette demande d\'intervention. Si le contact n\'est pas encore actualisé depuis la base de données, merci de patienté 24 heures.',
				icon: 'error',
				confirmButtonText: 'Fermer'
			})
			return false
		}
	})

    $('#backToTop').on('click', function(){
        $('html, body').animate({ scrollTop: 0 }, 'slow');
    })

    $('.js_attachments-anchor').on('click', function(e){
        e.preventDefault();
        $($(this).attr('href'))[0].scrollIntoView({
            behavior: 'smooth',
        });
    })
});
