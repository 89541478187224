
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

require('./components/dataTables.responsive');

//Ajout fct jQuery
require('./components/getSelector');

//Gestion du menu
require('./components/readyStorage');

//Gestion du générateur de module
require('./components/generator');

//Gestion des form switch
require('./components/switcher');

//Gestion des roles et ajax
require('./components/role');

//Gestion du step lors de la création de DI
require('./components/stepper');

//Gestion des champs au format monaitaire
require('./components/autonumeric');

//Gestion de l'ajout des types de travail sur le step2 de la DI
require('./components/work_system');

//Ajax de recherche de client
require('./components/search_customer_info');

//Select2 de recherche de contact
require('./components/search_contact_id');

//Ajax de recherche adresse client
require('./components/search_delivery_address');

//Ajax de recherche de pieces
require('./components/search_piece');

//Ajax de recherche de resource (Hommes)
require('./components/search_resource');

//Ajax de recherche de sites
require('./components/search_site');

//Ajax des rapports
require('./components/rapport');

//Gestion général du datepicker
require('./components/datepicker');

//Recherche ajax du RS
require('./components/search_rs');

//Verification de la demande d'intervention
require('./components/verification_intervention');

//Envoi de notification au secretariat pour l'ajout d'un contact
require('./components/send_notification_secretary');

//Permet de toggle la ligne d'un datatable
require('./components/show_more');

//Gestion du TDR
require('./components/rentability');

//Gestion des heures a ajouter au client sans pointage
require('./components/bankhours');

//Modification de l'url du form lors d'un changement de statut
require('./components/form_url_change_statut.js');

//Gestion des semaines par rapport a une année
require('./components/get_weeks.js');

//Gestion des utilisateurs (création, ...)
require('./components/user.js')

//Gestion des numéros de facture
require('./components/bill_number.js')

$(function () {
    $('[data-toggle="tooltip"]').tooltip()
})
