$(document).ready(function(){
	$('.js_collaborator').select2({
		ajax: {
			url: route('collaborators.getSelect'),
			dataType: 'json',
			processResults: function (data) {
				data.collaborators.forEach(site => {
					site.text = site.code + " - " + site.name
				})
				return {
					results: data.collaborators,
				}
			}
		},
	})

    // if($('#js_collaborator-id').length > 0 && $('input[name="old_collaborator_id"]').length > 0){
    //     // console.log($('input[name="old_collaborator_id"]').first().val());
    //     var oldCollaborator = $('input[name="old_collaborator_id"]').first().val()
    //     if($('#js_collaborator-id').val() == null){
    //         console.log(oldCollaborator);
    //         $('#js_collaborator-id').val(oldCollaborator);
    //         $('#js_collaborator-id').trigger('change');
    //         console.log($('#js_collaborator-id').val());
    //     }
    // }
});
