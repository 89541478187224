$(document).ready(function(){
	$('#rs1_user_id').on('change', function(){
        if ($('#rs1_user_id').val())
        {
            $.get(route('users.getrs', {user: $('#rs1_user_id').val()}))
            .done(function( data ) {
                $('#email_rs_1').val(data.email);
                $('#phone_rs_1').val(data.phone);
            });

            //On empeche d'avoir le meme contact que le remplacant
            if($('#rs1_user_id').val() == $('#rs2_user_id').val()){
                $('#rs2_user_id').find('option:selected').prop('selected',false)
                $('#email_rs_2,#phone_rs_2').val('');
            }
            $('#rs2_user_id,#rs1_user_id').find('option').removeClass('d-none');
            //
        }
        else
        {
            $('#email_rs_1').val('');
            $('#phone_rs_1').val('');
        }
	})

	$('#rs2_user_id').on('change', function(){
        if ($('#rs2_user_id').val())
        {
            $.get(route('users.getrs', {user: $('#rs2_user_id').val()}))
            .done(function( data ) {
                $('#email_rs_2').val(data.email);
                $('#phone_rs_2').val(data.phone);
            });

            //On empeche d'avoir le meme contact que le remplacant
            if($('#rs1_user_id').val() == $('#rs2_user_id').val()){
                $('#rs1_user_id').find('option:selected').prop('selected',false)
                $('#email_rs_1,#phone_rs_1').val('');
            }
            $('#rs1_user_id,#rs2_user_id').find('option').removeClass('d-none');
            //
        }
        else
        {
            $('#email_rs_2').val('');
            $('#phone_rs_2').val('');
        }

	})
});
