$(document).ready(function(){
	$(document).on("click",".js_add-ref-in-work-list", function(){
        if($('.js_work-'+$('#work_id').val()).length){
            alert("Cet article est déjà dans la liste.")
        }else{
            $.ajax({
                url : route('work.lineget', {work: $('#work_id').val(), intervention: $('#intervention_id').val()}),
                type : 'GET',

                dataType : 'html',
                success : function(code_html, statut){
                    $('.js_list-work').prepend(code_html)

                    //Setting autonumeric on fields
                    $('.js_currency').each(function(i, e) {
                        if (!AutoNumeric.isManagedByAutoNumeric(e))
                            new AutoNumeric(e, {
                                suffixText: " €",
                                decimalCharacter: ",",
                                decimalCharacterAlternative: ".",
                                digitGroupSeparator: ".",
                                unformatOnSubmit: true
                            }).french;
                    });

                    $('#work_id').val(null).trigger('change')
                }
            })
        }
    })

    $('body').on('click', '.js_close-card-work' , function(){
        $(this).closest('.card-body').parent('.card').fadeOut(300, function() {
            $(this).remove()
        })
    });

    $('body').on('change', '.js_qtt' , function(){
        console.log('changeTotal');
        changeTotal($(this));
    });

    $('body').on('change', '.js_currency' , function(){
        changeTotal($(this));
    });

    // console.log($('#collaborator_id').val());

    $('#work_id').select2({
        ajax: {
            url: route('work.ajaxget', [$('#collaborator_id').val(), $('#intervention_id').val()]),
            dataType: 'json',
            processResults: function (data) {
                data.works.forEach(work => {
                    work.text = work.reference + " - " + work.designation;
                })
                return {
                    results: data.works,
                }
            }
        },
    })
});

function changeTotal(elem){
    prix = elem.parent().parent().find('.js_currency').val()
    prix = prix.replace(" ", "")
    prix = prix.replace(".", "")
    prix = prix.replace(",", ".")
    prix = parseFloat(prix)
    qtt = elem.parent().parent().find('.js_qtt').val()
    total = prix * qtt;
    elem.parent().parent().find('.js_total').val(total.toFixed(2) + ' €');

}
