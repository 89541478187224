$(document).ready(function(){
	// Generator
    // ADD FIELD
	if($('#js_generatorForm').length > 0){
		// Nb element
		let fieldNb = 0;

		// Increment the fields number depending of the number of fields present on the page
		$('div[data-number]').each(function(){
			fieldNb++;
		});

		// Add a new field
		$('#js_addField').click(function(e){
			e.preventDefault();
			addField(fieldNb);
			fieldNb++;
		});

		// Remove a field
		$('.js_removeField').click(function(e){
			e.preventDefault();
			$('div[data-number="' + $(this).attr("data-number") + '"]').remove();
		});

		// Listener on the foreign key
		$('.js_fk').click(addForeignKeyFields);

		// Listener on the generator checkbox
		$('.js_crud').change(function(){
			if($(this).prop('checked')){
				$('.js_alert-warning').toggleClass('d-none');

				// Add minimum 4 fields
				/*if($('.js_field-prop[data-number]').length == 1){
					let allPromise = [];
					
					for(fieldNb; fieldNb <= 3; fieldNb++){
						allPromise.push(addField(fieldNb, false));
					}

					Promise.all(allPromise).then((datas) => {
						datas.forEach(function(data){
							displayedNewField(data.data, data.fieldNb);
						});
					});
				}*/

				if($(this).attr('name') == 'crud' && !$('[type="checkbox"][name="model"]').prop('checked')){
					$('[type="checkbox"][name="model"]').prop('checked', true);
				}
			}else if(!$('.crud[name="model"]').prop('checked') && !$('.crud[name="crud"]').prop('checked')){
				$('.js_alert-warning').hide();
			}

			if($(this).attr('name') == 'model' && !$(this).prop('checked') && $('[type="checkbox"][name="crud"]').prop('checked')){
				$('[name="crud"]').prop('checked', false);
				$('.js_alert-warning').hide();
			}
		});

		// Listener on the type
		$('.js_select').change(disabledLength);

		// Disabled type
		$('.js_select').each(function(){
			disabledLength({target: $(this)});
		});
	}
});

// Create a HTML element
let createElement = (container, attrs = {}) => {
	let element = document.createElement(container);

	if(attrs){
		for(let key in attrs){
			if(key === 'class'){
				for(let className in attrs[key]){
					element.classList.add(attrs[key][className]);
				}
			}else if(key === 'attr'){
				for(let attrName in attrs[key]){
					element.setAttribute(attrName, attrs[key][attrName]);
				}
			}
		}
	}

	return element;
};


// Function to display the reference table and reference column field when foreign key is checked
let addForeignKeyFields = (event) => {
	let referenceCol;
	let referenceColLabel;
	let referenceTab;
	let referenceTabLabel;
	let id = $(event.target).attr('data-id');
	let selectType = $('[name="fields[' + id + '][type]"]');

	if(event.data && event.data.referenceCol && event.data.referenceColLabel){
		referenceCol = event.data.referenceCol;
		referenceColLabel = event.data.referenceColLabel;
	}else{
		referenceCol = $('[name="fields[' + id + '][referenceCol]"]');
		referenceColLabel = $('[for="fields[' + id + '][referenceCol]"]');
	}

	if(event.data && event.data.referenceTab && event.data.referenceTabLabel){
		referenceTab = event.data.referenceTab;
		referenceTabLabel = event.data.referenceTabLabel;
	}else{
		referenceTab = $('[name="fields[' + id + '][referenceTab]"]');
		referenceTabLabel = $('[for="fields[' + id + '][referenceTab]"]');
	}

	// Show referenced fields
	if($(event.target).is(':checked')){
		$(referenceCol).removeClass("hidden");
		$(referenceColLabel).removeClass("hidden");
		$(referenceTab).removeClass("hidden");
		$(referenceTabLabel).removeClass("hidden");

		// Disabled type and select integer type
		selectType.children().each(function(){
			$(this).removeAttr('selected');
		});

		selectType.find('[value="integer"]').attr('selected', true);
		selectType.attr('disabled', true);
	}else{
		$(referenceCol).addClass("hidden");
		$(referenceColLabel).addClass("hidden");
		$(referenceTab).addClass("hidden");
		$(referenceTabLabel).addClass("hidden");
		selectType.attr('disabled', false);
	}
};

let disabledLength = (event) => {
	let select = $(event.target);
	let id = select.attr('data-id');

	if(select.val() == 'date' || select.val() == 'datetime' || select.val() == 'boolean'){
		$('[name="fields[' + id + '][length]"]').attr('disabled', true);
		$('[name="fields[' + id + '][length]"]').val('');
	}else{
		$('[name="fields[' + id + '][length]"]').attr('disabled', false);
	}
};

// Function to add field
let displayedNewField = (data, fieldNb) => {
	let fieldContainer = $('#js_fieldsContainer');
	fieldContainer.append(data);
	data = fieldContainer.children().last();
	
	// Listener on the foreign key
	data.find('[name="fields[' + fieldNb + '][fk]"]:not([type="hidden"])').click({
		referenceCol: data.find('[name="fields[' + fieldNb + '][referenceCol]"]'), 
		referenceColLabel: data.find('[for="fields[' + fieldNb + '][referenceCol]"]'), 
		referenceTab: data.find('[name="fields[' + fieldNb + '][referenceTab]"]'), 
		referenceTabLabel: data.find('[for="fields[' + fieldNb + '][referenceTab]"]')
	}, addForeignKeyFields);

	// Listener on the remove button
	data.find('.js_removeField').click(function(e){
		e.preventDefault();
		$(this).parent().remove();
	});

	// Listener on the type
	data.find('[name="fields[' + fieldNb + '][type]"]').change(disabledLength);

	return data;
};

// Function to add field
let addField = (fieldNb, display = true) => {
	return new Promise((resolve, reject) => {
		$.ajax({
			url: route('generator.addField'),
			method: 'post',
			dataType: 'html',
			data: {'_token': $('[name="_token"]').val(), fieldNb: fieldNb},
			success: (data) => {
				if(display) data = displayedNewField(data, fieldNb);
				resolve({data: data, fieldNb: fieldNb});
			},
			error: (data) => {
				console.error(data);
				reject(data);
			}
		});
	});
};
