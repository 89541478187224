$(document).ready(function(){
	//$('.js_customer-code').on('keyup', syncCustomer)
	$('#delivery_address').on('change', syncDelivery)
});

let syncDelivery = () => {
	if($('#delivery_address').val()){
		$.get(route('deliveryaddress.get', {id: $('#delivery_address').val()}), function( data ) {
			if(data){

                $('#delivery_address_address,#delivery_address_address,#delivery_address_address_bis,#delivery_address_postal_code,#delivery_address_city,#delivery_address_country').prop('readonly', true);
				$('#delivery_address_address').val(data.address);
				$('#delivery_address_address_bis').val(data.address_bis);
				$('#delivery_address_postal_code').val(data.postal_code);
				$('#delivery_address_city').val(data.city);
				$('#delivery_address_country').val(data.country);

			}else{

			}
		});
	}else{
        $('#delivery_address_address,#delivery_address_address,#delivery_address_address_bis,#delivery_address_postal_code,#delivery_address_city,#delivery_address_country').prop('readonly', false);
		$('#delivery_address_address').val('');
		$('#delivery_address_address_bis').val('');
		$('#delivery_address_postal_code').val('');
		$('#delivery_address_city').val('');
		$('#delivery_address_country').val('');
	}

}
