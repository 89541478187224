$(document).ready(function(){
	$('.js_btn-sync-piece').on('click', syncPiece)
	$('.js_btn-choose_working-type').on('click', function(){
		$('#working-type').val($(this).data('type'))
	})
	//$('.js_piece').on('keyup', syncPiece)
	$('body').on('click','.ui-menu-item', syncPiece)
});

$('#piece_id').autocomplete({
	minLength: 3,
	source: function( request, response ) {
		$.ajax({
			url: route('pieces.ajaxget'),
			dataType: "json",
			data: {
				term: request.term
			},
			success: function( data ) {
				response( data );
			}
		});
	},
	select: syncPiece,
});

let syncPiece = () => {
	$.get(route('piece.get', {piece: $('.js_piece').val()}), function( data ) {
		if(data){
			$('#designation').val(data.designation)
		}else{
			$('.js_remove-value').val('');
		}
	});
}
