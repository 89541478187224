$(document).on('click', '.add_di_bill_number', function(){
    let clone = $(this).closest('.di_container').clone();
    clone.find('.di_input').val('');
    console.log(clone)
    clone.insertAfter($('.di_container').last());
    $()
});

$(document).on('click', '.del_di_bill_number', function(){
    if($('.di_container').length > 1){
        $(this).closest('.di_container').remove();
    }
    else{
        $(this).closest('.di_container').find('.di_input').val('');
    }
});
