import { runInContext } from "vm";

$(document).ready(function(){
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    //Cette fonction permet d'empecher une erreur lors du clic sur un label
    //$('body').on('click','label',function(e){
    //    e.preventDefault();
    //})

    //Cette fonction permet d'initialiser les datepickers et clockpickers
    var initializeSpecialPickers = function(){
        jQuery.datetimepicker.setLocale('fr');
        $('.js_rapport-date').datetimepicker({
            timepicker:false,
            format:'d/m/Y',
            dayOfWeekStart: 1,
            scrollMonth : false
        });

        // $('.js_rapport-hour_input').clockpicker({
        //     autoclose: true
        // });
    }
    initializeSpecialPickers();

    //Cette fonction permet de mettre à jour la cadence
    var setPiecesPerHours = function(element){
        var nombre_pieces = 0;
        $(element).find('.js_pieces').each(function(index, value){
            if($(this).val().length != 0 && $(this).val() != null && $(this).val() >= 0){
                nombre_pieces += parseInt($(this).val());
            }
        })

        if(element.find('input[name="hours_count[]"]').val() != null && element.find('input[name="hours_count[]"]').val() != 0){
            var cadence = Math.abs(nombre_pieces / element.find('input[name="hours_count[]"]').first().val());
            console.log(cadence.toFixed(2));
            element.find('.js_cadence').val(cadence.toFixed(2));
        }
    }

    //Cette fonction permet d'ajouter des lignes des rapport
    $('.js_rapport-ajout-ligne').click(function(e){
        var new_line = $('.js_rapport-ligne_origin .js_rapport-ligne').first().clone();

        new_line.removeClass('d-none');

        new_line.find('input:not([name="remark[]"])').removeAttr('value');

        new_line.find('.js_rapport-hour_input').val('00');

        new_line.find('.js_cadence').val('0');

        new_line.find('.js_notedefrais').val('0');

        new_line.find('.js_hours-count').val('0');

        new_line.find('.js_pieces').val(0)

        new_line.find('.js_comment_report').val(null);

        new_line.find('.js_deleteLine').removeClass('d-none');

        new_line.find('.js_rapport-hour_input').attr('disabled', false);

        new_line.find('.js_remark-rapport').val(1)

        new_line.find('.js_entries_hidden,.js_exits_hidden').val('00')

        new_line.find('select[name="work[]"]').val('');

        new_line.addClass('js_rapport-ligne_row');

        new_line.insertBefore($('.js_rapport-ligne_row').first());

        initializeSpecialPickers();
    })

    //Cette fonction permet de blocker les heures si abs
    $('body').on('change','.js_remark-rapport',function(){
        if($(this).val() != 1){
            $(this).parents('.js_rapport-ligne').find('.js_rapport-hour_input').attr('readonly', true)

            //Dans les 6 paragraphes suivants, les 2 premières lignes correspondent aux deux inputs d'une entrée ou sortie
            //La 3eme ligne correspond à l'input hidden qui réprésente cette entrée ou sortie
            $(this).parents('.js_rapport-ligne').find('.js_rapport-hour_input:eq(0)').val('00')
            $(this).parents('.js_rapport-ligne').find('.js_rapport-hour_input:eq(1)').val('00')
            $(this).parents('.js_rapport-ligne').find('.js_entries_hidden:eq(0)').val('00:00')

            $(this).parents('.js_rapport-ligne').find('.js_rapport-hour_input:eq(2)').val('00')
            $(this).parents('.js_rapport-ligne').find('.js_rapport-hour_input:eq(3)').val('00')
            $(this).parents('.js_rapport-ligne').find('.js_exits_hidden:eq(0)').val('00:00')

            $(this).parents('.js_rapport-ligne').find('.js_rapport-hour_input:eq(4)').val('00')
            $(this).parents('.js_rapport-ligne').find('.js_rapport-hour_input:eq(5)').val('00')
            $(this).parents('.js_rapport-ligne').find('.js_entries_hidden:eq(1)').val('00:00')

            $(this).parents('.js_rapport-ligne').find('.js_rapport-hour_input:eq(6)').val('00')
            $(this).parents('.js_rapport-ligne').find('.js_rapport-hour_input:eq(7)').val('00')
            $(this).parents('.js_rapport-ligne').find('.js_exits_hidden:eq(1)').val('00:00')

            $(this).parents('.js_rapport-ligne').find('.js_rapport-hour_input:eq(8)').val('00')
            $(this).parents('.js_rapport-ligne').find('.js_rapport-hour_input:eq(9)').val('00')
            $(this).parents('.js_rapport-ligne').find('.js_entries_hidden:eq(2)').val('00:00')

            $(this).parents('.js_rapport-ligne').find('.js_rapport-hour_input:eq(10)').val('00')
            $(this).parents('.js_rapport-ligne').find('.js_rapport-hour_input:eq(11)').val('00')
            $(this).parents('.js_rapport-ligne').find('.js_exits_hidden:eq(2)').val('00:00')

            $(this).parents('.js_rapport-ligne').find('.js_hours-count').val(7)

            //On remet les pieces a 0
            $(this).parents('.js_rapport-ligne').find('#pieces_ok,#pieces_ko,#retouch').val(0)
            setPiecesPerHours($(this).parents('.js_rapport-ligne'));


            //On cache les éléments préremplis
            $(this).parents('.js_rapport-ligne').find('.js_date-displayer').removeClass('col-lg-6').addClass('mb-0');
            $(this).parents('.js_rapport-ligne').find('.js_hours-displayer').removeClass('d-flex');
            $(this).parents('.js_rapport-ligne').find('.js_hours-displayer,.js_pieces-displayer').addClass('d-none');
        }else{
            //On affiche les éléments cachés
            $(this).parents('.js_rapport-ligne').find('.js_date-displayer').addClass('col-lg-6').removeClass('mb-0');
            $(this).parents('.js_rapport-ligne').find('.js_hours-displayer').addClass('d-flex');
            $(this).parents('.js_rapport-ligne').find('.js_hours-displayer,.js_pieces-displayer').removeClass('d-none');
            //

            $(this).parents('.js_rapport-ligne').find('.js_rapport-hour_input').attr('readonly', false)
            $(this).parents('.js_rapport-ligne').find('.js_rapport-hour_input').val('00')
            $(this).parents('.js_rapport-ligne').find('.js_entries_hidden,.js_exits_hidden').val('00:00')
            $(this).parents('.js_rapport-ligne').find('.js_hours-count').val('0')
        }
    });

    //Cette fonction permet de supprimer des lignes de rapport
    $('body').on('click','.js_rapport-suppr-ligne',function(){
        // if($('.js_rapport-ligne').length > 1){
            $(this).parent().parent().parent().remove()
        // }
    });

    //Cette fonction permet de mettre à jour le nombre d'heures travaillées
    $('body').on('blur','.js_rapport-hour_input',function(){
        //On force la valeur de l'input en deux chiffres
        let formattedInputVal = ("0" + $(this).val()).slice(-2);
        $(this).val(formattedInputVal);

        var hoursEntry = []
        var minutesEntry = [];
        var hoursExit = [];
        var minutesExit = []
        var entries = [];
        var exits = [];

        //On met les minutes et les heures des entrees dans deux tableaux pour plus tard
        $(this).parents('.js_rapport-ligne').find('.js_entree_heures').each(function(index){
            hoursEntry[index] = $(this).val() || '00';
        });
        $(this).parents('.js_rapport-ligne').find('.js_entree_minutes').each(function(index){
            minutesEntry[index] = $(this).val() || '00';
        });

        //On met les minutes et les heures des sorties dans deux tableaux pour plus tard
        $(this).parents('.js_rapport-ligne').find('.js_sortie_heures').each(function(index){
            hoursExit[index] = $(this).val() || '00'
        });
        $(this).parents('.js_rapport-ligne').find('.js_sortie_minutes').each(function(index){
            minutesExit[index] =$ (this).val() || '00'
        });

        //On met a jour les tableaux d'entrees et de sortie en mettant des données moment pour un calcul plus tard
        hoursEntry.forEach((entreeHeure, index) => {
            entries.push(moment(hoursEntry[index]+':'+minutesEntry[index], 'HH:mm'))
        })

        hoursExit.forEach((sortieHeure, index) => {
            exits.push(moment(hoursExit[index]+':'+minutesExit[index], 'HH:mm'))
        })

        //On met a jour les input hidden au format HH:mm (ceux qui seront envoyés dans la requête)
        entries.forEach((entry, index) => {
            $(this).closest('.js_rapport-ligne').find('.js_entries_hidden:eq('+index+')').val(moment(hoursEntry[index]+':'+minutesEntry[index], 'HH:mm').format('HH:mm'));
            $(this).closest('.js_rapport-ligne').find('.js_exits_hidden:eq('+index+')').val(moment(hoursExit[index]+':'+minutesExit[index], 'HH:mm').format('HH:mm'));
        })

        var milliseconds = 0;

        //On fait la somme de la différence entre les entrées et sorties en millisecondes pour la précision
        $.each(entries,function(index,value){

            var entree_calc = entries[index];
            var sortie_calc = exits[index];


            if(entree_calc > sortie_calc){
                sortie_calc = sortie_calc.add(1, 'd');
            }

            milliseconds += sortie_calc.diff(entree_calc, 'milliseconds');
        });

        var heures_float = milliseconds / 3600000;

        $(this).parents('.js_rapport-ligne').find('input[name="hours_count[]"]').val( (heures_float > 0)? heures_float.toFixed(2) : 0 );

        setPiecesPerHours($(this).parents('.js_rapport-ligne'));
    });

    //Cette fonction permet d'appeler la fonction de cadence au changement du nombre de pieces
    $('body').on('change','.js_pieces',function(){
        setPiecesPerHours($(this).parents('.js_rapport-ligne'));
    });

    $('#registration_number').change(function(){
        if($(this).val().length > 0 && $(this).val() != null){
            $.ajax({
                type: "POST",
                url: route('dailyreport.getResource'),
                dataType: 'JSON',
                data: {
                    'registration_number': $(this).val(),
                },
                success: function(retour){
                    console.log(retour);
                    $('#lastname').val(retour.resource.lastname);
                    $('#firstname').val(retour.resource.firstname);
                    $('#resource_type').val(retour.resource.resource_type.name);
                },
            });
        }
    });
})
