$(document).ready(function(){
    table1 = $('#tableHoraires').DataTable();

    var countColNumbHours = 0
    var countColNumbPieces = 0
    var countColNumbDays = 0

    $('#tableHoraires').on( 'draw.dt', function () {
        sumOfColumns = table1.data().columns().nodes().length;

        countColNumbHours = ($('th.tdr-hours').length / 2) / 2
        countColNumbPieces = ($('th.tdr-pieces').length / 2) / 2
        countColNumbDays = ($('th.tdr-days').length / 2) / 2

        for (var i = 4; i < sumOfColumns; i++) {
            initCompleteTable(table1, table1.column(i))
        }



        //for (let pas = 9; pas < 9 + countColNumbHours; pas++) {
        //    toggleSwitcher([pas], table1, this.checked)
        //}
        //for (let pas = 9 + countColNumbHours; pas < 9 + countColNumbHours + countColNumbPieces; pas++) {
        //    toggleSwitcher([pas], table1, this.checked)
        //}
        //for (let pas = 9 + countColNumbHours + countColNumbPieces; pas < 9 + countColNumbHours + countColNumbPieces + countColNumbDays; pas++) {
        //    toggleSwitcher([pas], table1, this.checked)
        //}
    });


    //init

    $('#js_show-tdr-donnees-pointage').change(function(){
        toggleSwitcher([4,5,6,7,8], table1, this.checked)
    });

    $('#js_show-tdr-fact-heure').change(function(){
        for (let pas = 9; pas < 9 + countColNumbHours; pas++) {
            toggleSwitcher([pas], table1, this.checked)
        }
    });

    $('#js_show-tdr-fact-piece').change(function(){
        for (let pas = 9 + countColNumbHours; pas < 9 + countColNumbHours + countColNumbPieces; pas++) {
            toggleSwitcher([pas], table1, this.checked)
        }
    });

    $('#js_show-tdr-fact-jour').change(function(){
        for (let pas = 9 + countColNumbHours + countColNumbPieces; pas < 9 + countColNumbHours + countColNumbPieces + countColNumbDays; pas++) {
            toggleSwitcher([pas], table1, this.checked)
        }
    });

    $('#js_show-tdr-autre').change(function(){
        toggleSwitcher([(8 + countColNumbHours + countColNumbPieces + countColNumbDays + 1),(8 + countColNumbHours + countColNumbPieces + countColNumbDays + 2),(8 + countColNumbHours + countColNumbPieces + countColNumbDays + 3),(8 + countColNumbHours + countColNumbPieces + countColNumbDays + 4)], table1, this.checked)
    });

    //Filter
    $(document).on('change', 'select[name="month"]', function(){
        $('input[name="date_before"],input[name="date_after"]').val('');
    })

})

function toggleSwitcher(array, table, value){
    array.forEach(function(item){
        var column = table.column( item );
        column.visible( value );
    });
}

function initCompleteTable (table, column){
    sum = 0;
    column
        .data()
        .each( function ( value, index ) {
            valeur = parseFloat(value)
            if(!valeur){
                valeur = parseFloat($(value).attr("data-money"))
            }
            sum += valeur ? valeur : 0
        })

    if(!$(column.footer()).hasClass('tx-horaire')){
        $( column.footer() ).html(sum.toFixed(2))
    }
}
