$(document).ready(function(){
	
	if(localStorage.getItem("menu") == null){
		localStorage.setItem("menu", 0)
	}

	if(localStorage.getItem("menu") == 0){
		$('body').addClass('sidebar-minimized brand-minimized')
	}else{
		$('body').removeClass('sidebar-minimized brand-minimized')
	}

	$('.js_storage-menu').click(function(){
		if(localStorage.getItem("menu") == 0){
			localStorage.setItem("menu", 1)
		}else{
			localStorage.setItem("menu", 0)
		}
	});
	
});
