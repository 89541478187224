import Stepper from 'bs-stepper'


$(document).ready(function () {
	$('.js_bs-stepper').each(function( index ) {
		var stepper = new Stepper($('.js_bs-stepper')[index], {
			animation: true,
		})

		if($(this).data('step')){
			stepper.to($(this).data('step'))
		}
	});
})